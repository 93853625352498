import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, PaginationSimple } from "../components"

export default ({ data, pageContext }) => {
  const {
    excerpts_title,
    excerpts_text,
    excerpts_author,
    excerpts_source,
  } = data.airtable.data

  const { previous, next } = pageContext

  // console.log("excerpt.js prev next", previous, next)
  const prevTitle = previous ? previous.data.excerpts_title : null
  const nextTitle = next ? next.data.excerpts_title : null
  const prevRow = previous ? previous.data.excerpts_row : null
  const nextRow = next ? next.data.excerpts_row : null
  const allItemPage = "/leftover/excerpts/1"
  const oneItemPage = "/leftover/excerpt/"

  return (
    <Wrapper>
      <Layout>
        <div className="section-center">
          <h2>Egypercesek</h2>
          <h3>{excerpts_title}</h3>
          <h4>{excerpts_author}</h4>
          <h5>( {excerpts_source} )</h5>
          <div
            className="info"
            dangerouslySetInnerHTML={{
              __html: excerpts_text.childMarkdownRemark.html,
            }}
          ></div>
        </div>

        <PaginationSimple
          oneItemPage={oneItemPage}
          allItemPage={allItemPage}
          prevTitle={prevTitle}
          nextTitle={nextTitle}
          prevRow={prevRow}
          nextRow={nextRow}
          pageContext={pageContext}
        />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  background: var(--clr-light-10);
  padding-top: 9rem;
  padding-bottom: 0rem;

  display: flex;
  flex-direction: column;

  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }

  .section-center {
    width: 60%;
    margin-bottom: auto;
    h2 {
      text-align: center;
      padding-bottom: 2rem;
    }

    h3 {
      ${"" /* text-align: center; */}
      padding-bottom: 1rem;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 500;
    }

    h4 {
      text-align: center;
      text-transform: uppercase;
    }

    h5 {
      padding-bottom: 1rem;
      text-align: center;
    }

    .info {
      padding-left: 10%;
      color: var(--clr-grey-1);
      border-left: 2px solid var(--clr-primary-5);
    }
  }
  @media (min-width: 768px) {
    .section-center {
      width: 70%;
    }
  }
  @media (min-width: 992px) {
    .section-center {
      width: 50%;
    }
  }
  @media (min-width: 1200px) {
    .section-center {
      width: 40%;
    }
  }
`

export const query = graphql`
  query($recordId: String!) {
    airtable(recordId: { eq: $recordId }) {
      id
      recordId
      data {
        excerpts_title
        excerpts_excerpt
        excerpts_row
        excerpts_text {
          childMarkdownRemark {
            html
          }
        }
        excerpts_author
        excerpts_source
        excerpts_created
      }
    }
  }
`
